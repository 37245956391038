// 封装localstorage对token(对象)的三个操作
// 1. 保存  // 2. 获取  // 3. 删除
// JSON
//token

//头像
// const AVATAR = 'avatar'
// // 1. 保存
// export const setAvatar = avatar => {
//   localStorage.setItem(AVATAR, JSON.stringify(avatar))
// }

// // 2. 获取
// export const getAvatar = () => {
//   return JSON.parse(localStorage.getItem(AVATAR))
// }

// // 3. 删除
// export const removeAvatar = () => {
//   localStorage.removeItem(AVATAR)
// }

//语言
const LANG = 'lang'
// 1. 保存
export const setLang = lang => {
  localStorage.setItem(LANG, JSON.stringify(lang))
}

// 2. 获取
export const getLang = () => {
  return JSON.parse(localStorage.getItem(LANG))
}
