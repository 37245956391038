import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/home.vue')
  },

  {
    path: '/about',
    name: 'about',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/about.vue')
  },
  {
    path: '/business',
    name: 'business',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/business.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "about" */ '../views/news.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/newViews/contact.vue')
  },
  {
    path: '/businessSon',
    name: 'businessSon',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/businessSon.vue')
  },
  {
    path: '/newsSon',
    name: 'newsSon',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/newsSon.vue')
  },
  {
    path: '/special',
    name: 'special',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/special.vue')
  },
  {
    path: '/distribution',
    name: 'distribution',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/distribution.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/register.vue') //药物注册
  },
  {
    path: '/strategy',
    name: 'strategy',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/strategy.vue') //市场准入战略咨询
  },
  {
    path: '/alert',
    name: 'alert',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/alert.vue') //警戒
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

// 全局前置守卫:每次路由切换时，都会调用beforeEach
// to：要跳转到哪 from：当前导航要离开的路由信息 next是否继续跳转路由 如果要就调用next()
router.beforeEach((to, from, next) => {
  // 获取到前面打过标签的元素，将其scrollTop属性设置为0
  document.documentElement.scrollTop = 0
  // 滚动条可能出现在HTML元素上而不是body上，确保兼容性，同时将HTML和body中scrollTop属性设置为0
  document.body.scrollTop = 0
  // 调用继续路由跳转
  next()
})

export default router
