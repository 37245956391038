import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import { setLang, getLang } from '../utils/storage'

export default new Vuex.Store({
  state: {
    langs: getLang() || ''
  },
  getters: {},
  mutations: {
    //修改lang的方法
    setLang (state, str) {
      console.log('diaoyog')
      // 保存到vuex
      state.langs = str
      // 保存到本地存储
      setLang(str)
    }
  },
  actions: {},
  modules: {}
})
